@font-face {
    font-family: 'Roboto Regular';
    src: url('../fonts/roboto-v20-latin-regular.woff2') format('woff2'), url('../fonts/roboto-v20-latin-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto Bold';
    src: url('../fonts/roboto-v20-latin-700.woff2') format('woff2'), url('../fonts/roboto-v20-latin-700.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
