@font-face {
  font-family: 'icons';
  src: url('../fonts/icons.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"], [class*="icon-"] {
  font-family: 'icons';
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-caret-left:before {
  content: "\e906";
}
.icon-backspace:before {
  content: "\e907";
}
.icon-door:before {
  content: "\e908";
}
.icon-language:before {
  content: "\e90a";
}
.icon-locker:before {
  content: "\e90b";
}
.icon-arrow-right:before {
  content: "\e90c";
}
.icon-exit:before {
  content: "\e90d";
}
.icon-nfc-card-hand:before {
  content: "\e90e";
}
.icon-nfc-card-hand-at-sensor:before {
  content: "\e901";
}
.icon-nfc:before {
  content: "\e900";
}
.icon-pin:before {
  content: "\e911";
}
.icon-star:before {
  content: "\e912";
}
